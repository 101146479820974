import React, { useLayoutEffect, useState, forwardRef } from "react";
import styles from "./styles.module.css";

const DropDownAttribute = forwardRef(({ children, title, checked }, ref) => {
  const [opened, setOpened] = useState(false);
  const [count, setCount] = useState(false);

  useLayoutEffect(() => {
    if (checked) {
      setCount(true);
    }
  }, [checked]);

  const handleOpen = () => {
    if (count) {
      return true;
    }
  };

  return (
    <>
      <div ref={ref}>
        <div
          className={styles.titleBlock}
          onClick={() => {
            setOpened(!opened);
            setCount(!count);
          }}
        >
          <span
            style={{
              color: checked ? "#d10000" : "",
            }}
            className={styles.title}
          >
            {title}
          </span>
          <span
            className={styles.arrow}
            style={{
              transform: !handleOpen() ? "rotate(0deg)" : "rotate(180deg)",
            }}
          >
            <svg
              width="16"
              height="12"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.0293 5L4.99991 0.97083L9.02908 4.94145"
                stroke="#282828"
              ></path>
            </svg>
          </span>
        </div>
        {handleOpen() && <div className={styles.dropDownBlock}>{children}</div>}
      </div>
    </>
  );
});

export default DropDownAttribute;
