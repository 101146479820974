import React, { useEffect, useState } from "react";
import LoadContent from "components/LoadContent";
import Colors from "./components/colors/newColors";
import CountryOfManufacture from "./components/countryManufactur/newCountryOfManufacture";
import Cost from "./components/cost/newCost";
import CloseIcon from "assets/SVG/CloseSvg";
import Filter from "./components/filter/newIndex";
import langData from "lang/data";
import { ReactComponent as FilterIcon } from "assets/SVG/filterIcon.svg";
import { useSearchParams, useParams } from "react-router-dom";

const NewAttributes = ({
  styles,
  setCurrentPage,
  filters,
  loading,
  currentLanguage,
}) => {
  const [cost, setCost] = useState(false);
  const [color, setColor] = useState(false);
  const [sorting, setSorting] = useState(false);
  const [manufactures, setManufactures] = useState(false);
  const [attribute, setAtribute] = useState(false);
  const [openModal, setOpenModal] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [discount, setDiscount] = useState(false);
  const [searchDisabled, setSearchDisabled] = useState(false);
  const [priceDisabled, setPriceDisabled] = useState(false);
  const [activeAttribute, setActiveAtribute] = useState([]);

  let bParam = searchParams?.get("b");
  let activeBrands = [];
  if (bParam) {
    bParam = bParam.replace(/[^0-9,]/g, "");
    activeBrands = searchParams?.has("b")
      ? JSON.parse("[" + bParam + "]")
      : [];
  }

  let activeAttributes = [];
  let aParam = searchParams?.get("a");
  if (aParam) {
    aParam = aParam.replace(/[^0-9,]/g, "");
    activeAttributes = searchParams.has("a")
      ? searchParams.get("a").split(",")
      : [];
  }

  let activeCategories = [];
  let ctParam = searchParams?.get("ct");
  if (ctParam) {
    ctParam = ctParam.replace(/[^0-9,]/g, "");
    activeCategories = searchParams.has("ct")
      ? searchParams.get("ct").split(",").map(Number)
      : [];
  }
  const handleSelectCategory = (item) => { 
    const category_id = +item?.id;
    handleAddAttribute(langData?.buttons.catalog?.[currentLanguage])
    if (activeCategories.includes(category_id)) {
      activeCategories = activeCategories.filter((id) => id !== category_id);
    } else {
      activeCategories = [...activeCategories, category_id];
    }

    searchParams.delete("page");

    if (activeCategories.length == 0) {
      searchParams.delete("ct");
    } else { 
      if(searchParams?.get('ct')){
        searchParams.set("ct", activeCategories.join(",")); 

      }else{
        searchParams.append("ct", activeCategories.join(",")); 
      }
    }

    setSearchParams(searchParams);

  }
  const handleSelectBrand = (item) => {
    let updatedBrands;
    const brand_id = item?.id;

    // Проверяем, если бренд уже активен, удаляем его
    if (activeBrands.includes(brand_id)) {
      updatedBrands = activeBrands.filter((id) => id !== brand_id);
    } else {
      updatedBrands = [...activeBrands, brand_id];
    }

    // Удаляем параметр "b" из searchParams
    searchParams.delete("b");

    // Если есть активные бренды, добавляем их как параметр "b"
    if (updatedBrands.length > 0) {
      searchParams.set("b", updatedBrands.join(",")); // Собираем бренды через запятую
    }

    // Теперь создаём строку с параметрами, но ставим "b" в начало
    const newParams = new URLSearchParams();

    // Сначала добавляем параметр "b"
    if (searchParams.has("b")) {
      newParams.set("b", searchParams.get("b"));
    }

    // Затем добавляем все остальные параметры (в том порядке, в котором они были)
    searchParams.forEach((value, key) => {
      if (key !== "b") {
        newParams.append(key, value);
      }
    });
        newParams?.delete("page");
        setSearchParams(newParams);
  };

  const handleSelectSorting = (sort) => {
    searchParams.delete("page");

    if (searchParams.get("s") === sort) {
      searchParams.delete("s");
    } else {
      searchParams.set("s", sort);
    }
    setSearchParams(searchParams);
  };
  const handleSelectAttribute = (item, value) => {
    searchParams.delete("page");

    let attribute = `${item?.attribute_id}_${value?.id}`;

    let currentAttributes = searchParams.get("a");

    let attributesArray = [];

    if (currentAttributes) {
      attributesArray = currentAttributes.split(",");

      if (attributesArray.includes(attribute)) {
        attributesArray = attributesArray.filter((attr) => attr !== attribute);
      } else {
        attributesArray.push(attribute);
      }
    } else {
      attributesArray.push(attribute);
    }


    searchParams.delete("a");
    const foundAttribute = filters?.filters?.find(
      (attribute) => attribute.attribute_id === item?.attribute_id
    );

    const attributeName = foundAttribute?.[`name_${currentLanguage}`];
    handleRemoveAttribute(attributeName)
    if (attributesArray.length > 0) {
      searchParams.append("a", attributesArray.join(","));
    }

    setSearchParams(searchParams);
  };
  function getObjectById(id) {
    return filters?.filters?.find(
      (attribute) => attribute?.attribute_id === id
    );
  }

  const handleDiscount = () => {
    setSearchDisabled(true);
    setPriceDisabled(true);

    searchParams.delete("page");
    if (discount) {
      searchParams.delete("d");
    } else {
      searchParams.set("d", 1);
    }
    setDiscount(!discount);
    setSearchParams(searchParams);

    setSearchDisabled(false);
    setPriceDisabled(false);
  };
  useEffect(() => {
    setDiscount(searchParams.get("d"));
  }, []);

  const handleModal = (item) => {

    if (typeof item !== "string") {
      setAtribute(true);

      const result = getObjectById(item.id);


      setOpenModal(result);
    }

    if (item === langData?.product?.color?.[currentLanguage]) {
      setColor(true);
    }
    if (item === langData?.product?.cost?.[currentLanguage]) {
      setCost(true);
    }
    if (item === langData?.product?.manufactor?.[currentLanguage]) {
      setManufactures(true);
    }
    if (item === langData?.product?.sorting?.[currentLanguage]) {
      setSorting(true);
    }

    setOpenModal({ name_en: "filter" })

  };

  const handleAddAttribute = (item) => {
    if (!activeAttribute.includes(item)) {
      setActiveAtribute((prev) => [...prev, item]);
    }
  };

  const handleRemoveAttribute = (item) => {
    setActiveAtribute((prev) => prev.filter((attr) => attr !== item));
  };

  const handleRemoveAttributeClose = (item) => {

    const sortingItem = langData?.product?.sorting?.[currentLanguage];
    const costItem = langData?.product?.cost?.[currentLanguage];
    const manufactorItem = langData?.product?.manufactor?.[currentLanguage];
    const colorItem = langData?.product?.color?.[currentLanguage];


    setActiveAtribute((prev) => prev.filter((attr) => attr !== item));
    searchParams?.delete("page");

    if (sortingItem === item) {
      searchParams.delete("s");
    }
    if (costItem === item) {
      searchParams.delete("mx");
      searchParams.delete("mn");
      searchParams.delete("d");
      handleDiscount()
    }
    if (manufactorItem === item) {
      searchParams.delete("b");
    }

    if (langData?.buttons.catalog?.[currentLanguage] === item) {
      searchParams.delete("ct");
    }
    if (colorItem === item) {
      activeAttributes = activeAttributes.filter((attribute) => {
        const [attributeId] = attribute.split("_");
        return attributeId !== "3";
      });

      if (activeAttributes.length > 0) {
        searchParams.set("a", activeAttributes.join(","));
      } else {
        searchParams.delete("a");
      }
    }
    if (searchParams?.get("a")) {
      activeAttributes = activeAttributes.filter((attributePair) => {
        const ifa = attributePair.split("_");
        const attributeId = parseInt(ifa?.[0], 10);

        // Ищем атрибут по его ID
        const foundAttribute = filters?.filters?.find(
          (attribute) => attribute.attribute_id === attributeId
        );

        const attributeName = foundAttribute?.[`name_${currentLanguage}`];

        return attributeName !== item;
      });

      if (activeAttributes.length > 0) {
        searchParams.set("a", activeAttributes.join(","));
      } else {
        searchParams.delete("a");
      }
    }

    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (filters?.length == 0) return;
    const params = Object.fromEntries([...searchParams]);

    // Sorting
    const sortingItem = langData?.product?.sorting?.[currentLanguage];
    if (params?.s) {
      handleAddAttribute(sortingItem);
    } else {
      handleRemoveAttribute(sortingItem);
    }

    // Cost
    const costItem = langData?.product?.cost?.[currentLanguage];
    if ((params?.mn && params?.mx) || params?.d) {
      handleAddAttribute(costItem);
    } else {
      handleRemoveAttribute(costItem);
    }

    // Manufacturer
    const manufactorItem = langData?.product?.manufactor?.[currentLanguage];

    if (params?.b) {
      handleAddAttribute(manufactorItem);
    } else {
      handleRemoveAttribute(manufactorItem);
    }
    if (params?.ct) {
      handleAddAttribute(langData?.buttons.catalog?.[currentLanguage]);
    } else {
      handleRemoveAttribute(langData?.buttons.catalog?.[currentLanguage]);
    }
    const colorItem = langData?.product?.color?.[currentLanguage];

    const attributesArray = params?.a?.split(",") || [];


    attributesArray.forEach((attributePair) => {
      const ifa = attributePair.split("_");
      const attributeId = parseInt(ifa[0], 10);


      if (ifa?.[0] === "3") {
        handleAddAttribute(colorItem);
      } else if (!isNaN(attributeId)) {

        const foundAttribute = filters?.filters?.find(
          (attribute) => attribute.attribute_id === attributeId
        );
        const attributeName = foundAttribute?.[`name_${currentLanguage}`];

        if (attributeName) {
          handleAddAttribute(attributeName);
        }
      }
    });
    if (!params?.a) {
      handleRemoveAttribute(colorItem);
    }


    if (!Object?.keys(params).length) {
      setActiveAtribute([]);
    }

  }, [searchParams, filters]);

  return (
    <>
      {loading ? (
        <LoadContent containerClassName={styles.categoryAtributeLoad} />
      ) : (
        <>
          <div className={styles.categoryAtribute}>
            <div className={styles.categoryAtributeFix}>
              <FilterIcon onClick={() => setOpenModal({ name_en: "filter" })} />
            </div>
            <div className={styles.categoryContainer}>
              <span
                onClick={() => {
                  setSorting(true);
                }}
              >
                {langData?.product?.sorting?.[currentLanguage]}
              </span>
              <span onClick={() => setCost(true)}>
                {langData?.product?.cost?.[currentLanguage]}
              </span>

              {filters?.brandList?.length && !filters?.brand_seo && (
                <span onClick={() => setManufactures(true)}>
                  {langData?.product?.manufactor?.[currentLanguage]}
                </span>
              )}
              {filters?.filters?.find((filter) => filter.attribute_id === 3)
                ?.values?.length > 0 && (
                  <span onClick={() => setColor(true)}>
                    {langData?.product?.color?.[currentLanguage]}
                  </span>
                )}
              {filters?.filters?.map(
                (item) =>
                  !!item?.show_in_mobile && (
                    <span
                      key={item.id}
                      onClick={() => {
                        setOpenModal({ attr_id: item?.attribute_id,name_en:"filter" })
                      }}
                    >
                      {item?.["name_" + currentLanguage]}
                    </span>
                  )
              )}
            </div>
          </div>
          {!!activeAttribute?.length && (
            <div className={styles.categoryAtributeActive}>
              {[...new Set(activeAttribute)]?.map((item, index) => (
                <span
                  onClick={() => {
                    if (item?.styles && item?.styles === 1) {
                      setOpenModal({
                        name_en: "filter",
                      });
                    } else {
                      handleModal(item);
                    }
                  }}
                  key={index}
                >
                  {typeof item === "object"
                    ? item?.["name_" + currentLanguage]
                    : item}

                  <CloseIcon
                    onClick={(event) => {
                      // here
                      event.stopPropagation();
                      handleRemoveAttributeClose(item);
                    }}
                  />
                </span>
              ))}
            </div>
          )}
          {searchParams.get("fs") && (
            <div className={styles.categoryAtributeActive}>
              <span>
                {searchParams.get("fs")}
                <CloseIcon
                  onClick={(event) => {
                    searchParams.delete("fs");
                    setSearchParams(searchParams);
                  }}
                />
              </span>
            </div>
          )}
        </>
      )}

      <Filter
        data={openModal}
        handleDiscount={handleDiscount}
        discount={discount}
        onClearData={setOpenModal}
        filters={filters}
        currentLanguage={currentLanguage}
        handleSelectAttribute={handleSelectAttribute}
        handleSelectBrand={handleSelectBrand}
        handleSelectCategory={handleSelectCategory}
        activeCategories={activeCategories}
        handleSelectSorting={handleSelectSorting}
        activeAttributes={activeAttributes}
        activeBrands={activeBrands}
        searchDisabled={searchDisabled}
        setSearchParams={setSearchParams}
        priceDisabled={priceDisabled}
        searchParams={searchParams}
      />
      <Colors
        data={openModal}
        activeAttributes={activeAttributes}
        color={color}
        filters={filters}
        setColor={setColor}
        onClearData={setOpenModal}
        currentLanguage={currentLanguage}
        handleSelectAttribute={handleSelectAttribute}
      />
      <CountryOfManufacture
        data={openModal}
        sorting={sorting}
        setSorting={setSorting}
        attribute={attribute}
        setAtribute={setAtribute}
        onClearData={setOpenModal}
        manufactures={manufactures}
        setManufactures={setManufactures}
        filters={filters}
        currentLanguage={currentLanguage}
        handleSelectAttribute={handleSelectAttribute}
        handleSelectBrand={handleSelectBrand}
        handleSelectSorting={handleSelectSorting}
        activeAttributes={activeAttributes}
        activeBrands={activeBrands}
        searchParams={searchParams}
      />
      {cost && (
        <Cost
          currentLanguage={currentLanguage}
          filters={filters}
          searchParams={searchParams}
          data={cost}
          setCost={setCost}
          onClearData={setOpenModal}
          priceDisabled={priceDisabled}
          discount={discount}
          handleDiscount={handleDiscount}
        />
      )}
    </>
  );
};

export default NewAttributes;
